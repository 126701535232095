/**
 * @file Sagas for chat only
 * @author Alwyn Tan
 */

import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { navigate } from 'gatsby'
import { connectSocket, takeAndCloseChannel } from '../utils/sockets'
import {
  SOCKET_LOAD_CHAT_ROOMS,
  SOCKET_LOAD_CHAT_MESSAGES,
  SOCKET_SEND_CHAT_MESSAGE,
  SOCKET_CHAT_MESSAGE_LISTENER,
  SOCKET_CHAT_ROOM_LISTENER,
  SOCKET_SEEN_MESSAGE,
  SOCKET_CREATE_ROOM,
  SOCKET_GET_CHAT_ROOM,
} from '../constants'
import { normalizeObjectArray } from '../utils'
import {
  createChatRoom,
  getChatRoom,
  loadChatMessages,
  loadChatRooms,
  moveChatMessageToFront,
  moveChatRoomToFront,
  seenChatMessage,
  sendChatMessage,
  updateChatMessageDetails,
  updateChatMessages,
  updateChatRoomDetails,
  updateChatRooms,
} from '../actions/chat'
import { setAccessToken } from '../actions/auth'

// saga
// only initializes when the access token is received
function* initialize() {
  while (true) {
    const action = yield take(`${setAccessToken}`)
    const accessToken = action?.payload

    // if there is an access token being set
    if (accessToken) {
      const socket = yield call(connectSocket, accessToken)
      if (socket) return socket
    }
  }
}

function* startGetChatRoom(socket, action) {
  const roomID = action.payload
  const channel = eventChannel(emit => {
    socket.emit(SOCKET_GET_CHAT_ROOM, { roomID }, ({ room }) => {
      const { normalized } = normalizeObjectArray([room])
      emit([updateChatRoomDetails(normalized)])
    })
    return () => {}
  })

  yield call(takeAndCloseChannel, channel)
}

function* startLoadChatRooms(socket) {
  const existingRooms = yield select(state => state.chat.rooms)

  if (existingRooms.canLoadMore) {
    yield put(updateChatRooms({ loading: true }))

    const params = {}
    if (existingRooms.ids.length > 0)
      params.lastLoadedID = existingRooms.ids[existingRooms.ids.length - 1]

    const channel = eventChannel(emit => {
      socket.emit(SOCKET_LOAD_CHAT_ROOMS, params, ({ rooms, canLoadMore }) => {
        const { normalized, ids } = normalizeObjectArray(rooms)
        emit([
          updateChatRoomDetails(normalized),
          updateChatRooms({ ids, canLoadMore, loading: false }),
        ])
      })
      return () => {}
    })

    yield call(takeAndCloseChannel, channel)
  }
}

function* startLoadChatMessages(socket, action) {
  const { roomID } = action.payload
  const existingMessages = (yield select(
    state => state.chat.messages[roomID]
  )) || {
    ids: [],
    loading: false,
    canLoadMore: true,
  }

  if (existingMessages.canLoadMore) {
    yield put(updateChatMessages({ roomID, loading: true }))

    const params = { roomID }
    if (existingMessages.ids.length > 0)
      params.lastLoadedID =
        existingMessages.ids[existingMessages.ids.length - 1]

    const channel = eventChannel(emit => {
      socket.emit(
        SOCKET_LOAD_CHAT_MESSAGES,
        params,
        ({ messages, canLoadMore }) => {
          const { normalized, ids } = normalizeObjectArray(messages)
          emit([
            updateChatMessageDetails(normalized),
            updateChatMessages({ roomID, ids, canLoadMore, loading: false }),
          ])
        }
      )
      return () => {}
    })

    yield call(takeAndCloseChannel, channel)
  }
}

function startSendChatMessage(socket, action) {
  const { value, roomID } = action.payload
  socket.emit(SOCKET_SEND_CHAT_MESSAGE, { roomID, value })
}

function* startSeenChatMessage(socket, action) {
  const messageID = action.payload

  const channel = eventChannel(emit => {
    socket.emit(SOCKET_SEEN_MESSAGE, { messageID }, ({ room, message }) => {
      const nRoom = normalizeObjectArray([room])
      const nMessage = normalizeObjectArray([message])
      emit([
        updateChatRoomDetails(nRoom.normalized),
        updateChatMessageDetails(nMessage.normalized),
      ])
    })
    return () => {}
  })

  yield call(takeAndCloseChannel, channel)
}

function* startCreateChatRoom(socket, action) {
  const otherUser = action.payload
  const channel = eventChannel(emit => {
    socket.emit(
      SOCKET_CREATE_ROOM,
      { participants: [otherUser] },
      ({ room }) => {
        const { normalized } = normalizeObjectArray([room])
        emit([updateChatRoomDetails(normalized)])
        if (room) navigate(`/app/chat/${room.id}`)
      }
    )
    return () => {}
  })

  yield call(takeAndCloseChannel, channel)
}

function* listenToSocketEvents(socket) {
  // future notes: listener api might change to be less ambiguous of when these events get fired
  const channel = eventChannel(emit => {
    // listen for updates in chat room (makes the chat go up to the top)
    const chatRoomListener = ({ room }) => {
      const { normalized } = normalizeObjectArray([room])
      emit(updateChatRoomDetails(normalized))
      emit(moveChatRoomToFront(room))
    }

    // listen for new chat messages (makes the chat go up to the top)
    const chatMessageListener = ({ message }) => {
      const { normalized } = normalizeObjectArray([message])
      emit(updateChatMessageDetails(normalized))
      emit(moveChatMessageToFront(message))
    }

    socket.on(SOCKET_CHAT_ROOM_LISTENER, chatRoomListener)
    socket.on(SOCKET_CHAT_MESSAGE_LISTENER, chatMessageListener)

    return () => {
      socket.off(SOCKET_CHAT_ROOM_LISTENER, chatRoomListener)
      socket.off(SOCKET_CHAT_MESSAGE_LISTENER, chatMessageListener)
    }
  })

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* watchGetChatRoom(socket) {
  yield takeLatest(getChatRoom, startGetChatRoom, socket)
}

function* watchLoadChatRooms(socket) {
  yield takeLatest(loadChatRooms, startLoadChatRooms, socket)
}

function* watchLoadChatMessages(socket) {
  yield takeLatest(loadChatMessages, startLoadChatMessages, socket)
}

function* watchSendChatMessage(socket) {
  yield takeLatest(sendChatMessage, startSendChatMessage, socket)
}

function* watchSeenMessage(socket) {
  yield takeLatest(seenChatMessage, startSeenChatMessage, socket)
}

function* watchCreateChatRoom(socket) {
  yield takeLatest(createChatRoom, startCreateChatRoom, socket)
}

export default function* chatSaga() {
  const socket = yield call(initialize)

  if (socket) {
    yield fork(listenToSocketEvents, socket)
    yield fork(watchGetChatRoom, socket)
    yield fork(watchLoadChatRooms, socket)
    yield fork(watchLoadChatMessages, socket)
    yield fork(watchSendChatMessage, socket)
    yield fork(watchSeenMessage, socket)
    yield fork(watchCreateChatRoom, socket)
    // TODO: add a cancellable redux call to cancel task and close socket
  }
}
