/**
 * @file Constants for the entire website
 * @author Alwyn Tan
 */

export const BASE_API_URL = process.env.GATSBY_API_SERVER
export const BASE_SOCKET_URL = process.env.GATSBY_SOCKET_BASE_API

// waitlist
export const JOIN_WAITLIST_URL = `${BASE_API_URL}/access/waitlist`
export const WAITLIST_REF_CHECK_URL = `${BASE_API_URL}/access/waitlist-ref-check`
export const WAITLIST_SUBMIT_SURVEY_URL = `${BASE_API_URL}/access/waitlist-survey`

// auth routes
export const REQUEST_LOGIN_URL = `${BASE_API_URL}/auth/login-request`
export const LOGIN_URL = `${BASE_API_URL}/auth/login`
export const LOGOUT_URL = `${BASE_API_URL}/auth/logout`
export const REFRESH_TOKEN_URL = `${BASE_API_URL}/auth/refresh-token`

// events routes
export const GET_EVENT_URL = `${BASE_API_URL}/event`
export const EXPLORE_EVENTS_URL = `${BASE_API_URL}/event/explore`
export const GET_REGISTERED_EVENTS_URL = `${BASE_API_URL}/event/registered-events`
export const REGISTER_EVENT_URL = `${BASE_API_URL}/event/register-event`
export const UPDATE_EVENT_ADDITIONAL_INFO = `${BASE_API_URL}/event/update-additional-info`
export const GET_EVENT_MATCHES_URL = `${BASE_API_URL}/event/matches`
export const CONFIRM_EVENT_MATCHES_URL = `${BASE_API_URL}/event/confirmMatches`
export const CREATE_EVENT_URL = `${BASE_API_URL}/event/create`
export const UPDATE_EVENT_URL = `${BASE_API_URL}/event/update`
export const CANCEL_EVENT_URL = `${BASE_API_URL}/event/cancel`
export const UNREGISTER_EVENT_URL = `${BASE_API_URL}/event/unregister`
export const REGISTER_EVENT_WAITLIST_URL = `${BASE_API_URL}/event/register-waitlist`
export const UNREGISTER_EVENT_WAITLIST_URL = `${BASE_API_URL}/event/unregister-waitlist`

// user routes
export const USER_UPDATE_URL = `${BASE_API_URL}/user/update-user`
export const UPDATE_USERNAME_URL = `${BASE_API_URL}/user/update-username`
export const USER_INFO_URL = `${BASE_API_URL}/user/info`
export const VALIDATE_USERNAME_URL = `${BASE_API_URL}/user/validate-username`
export const LOAD_SUPERPOD_USERS_URL = `${BASE_API_URL}/user/superpod`

// stripe routes
export const CHECKOUT_EVENT_SESSION_URL = `${BASE_API_URL}/stripe/checkout-event-session`

// verification routes (request for perms, verify as host, etc)
export const REQUEST_SUPERPOD_VERIFICATION_URL = `${BASE_API_URL}/verification/request-superpod`
export const REQUEST_EMAIL_VERIFICATION_URL = `${BASE_API_URL}/verification/request-email`
export const VERIFY_EMAIL_URL = `${BASE_API_URL}/verification/verify-email`
export const FIND_VERIFICATIONS_URL = `${BASE_API_URL}/verification/find`

// chat socket routes
export const SOCKET_GET_CHAT_ROOM = 'chat/get-room'
export const SOCKET_LOAD_CHAT_ROOMS = 'chat/get-rooms'
export const SOCKET_LOAD_CHAT_MESSAGES = 'chat/get-messages'
export const SOCKET_SEND_CHAT_MESSAGE = 'chat/send-message'
export const SOCKET_SEEN_MESSAGE = 'chat/seen-message'
export const SOCKET_CHAT_MESSAGE_LISTENER = 'chat/listen-message' // received latest message
export const SOCKET_CHAT_ROOM_LISTENER = 'chat/listen-room'
export const SOCKET_CREATE_ROOM = 'chat/create-room'

// notification routes
export const GET_NOTIFICATIONS_URL = `${BASE_API_URL}/notification`

// pod/superpod routes
export const GET_SUPERPOD_URL = `${BASE_API_URL}/pod/superpod`

export const CHAT_MESSAGES_PER_LOAD = 15
export const CHAT_ROOMS_PER_LOAD = 10

export const ROLES = {
  ADMIN: 'admin',
  HOST: 'host',
}

export const CHAT_ROOM_TYPE = {
  DEFAULT: 'default',
  EVENT: 'event',
}

export const NOTIFICATION_TYPE = {
  STICKER: 'STICKER',
}

export const UNAUTHORIZED_ERROR_NAME = 'UNAUTHORIZED_ERROR'

export const lightTheme = {
  Accent: '#DD3F4E',
  Primary: '#F7F7FA',
  Secondary: '#FFFFFF',
  Tertiary: '#F0F1F5',
  Text: '#000000',
  Success: '#52BB8B',
  isDarkMode: false,
}

export const darkTheme = {
  Accent: '#DD3F4E',
  Primary: '#15181A',
  Secondary: '#292D2F',
  Tertiary: '#404446',
  Text: '#FFFFFF',
  Success: '#52BB8B',
  isDarkMode: true,
}
