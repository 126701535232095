/**
 * @file Reducers for all authentication
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  setAccessToken,
  setAuthLoading,
  setCurrentUser,
  setAuthPhoneNumber,
  setAuthSuccessCallback,
} from '../actions/auth'

const defaultState = {
  user: null,
  accessTokenLoaded: false,
  accessToken: null,
  isLoading: false,
  authPhoneNumber: null,
  authSuccessCallback: null,
}

export default handleActions(
  {
    // if access token is set, to anything, that means it's been loaded
    [`${setAccessToken}`]: (state, action) => ({
      ...state,
      accessToken: action.payload,
      accessTokenLoaded: true,
      isLoading: false,
    }),
    [`${setCurrentUser}`]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [`${setAuthLoading}`]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [`${setAuthPhoneNumber}`]: (state, action) => ({
      ...state,
      authPhoneNumber: action.payload,
    }),
    [`${setAuthSuccessCallback}`]: (state, action) => ({
      ...state,
      authSuccessCallback: action.payload,
    }),
  },
  defaultState
)
