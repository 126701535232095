/**
 * @file Manager for all the reducers of the app
 * @author Alwyn Tan
 */

import { combineReducers } from 'redux'

import auth from './auth'
import chat from './chat'
import events from './events'
import notifications from './notifications'
import pod from './pod'
import user from './user'
import verification from './verification'

export default combineReducers({
  auth,
  chat,
  events,
  notifications,
  pod,
  user,
  verification,
})
