/**
 * @file Notifications Saga
 * @author Alwyn Tan
 */

import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadNotifications,
  updateNotifications,
} from '../actions/notifications'
import { GET_NOTIFICATIONS_URL } from '../constants'
import { get } from '../utils/saga-fetch'
import { normalizeObjectArray } from '../utils'

function* startLoadNotifications() {
  const currNotifications = yield select(state => state.notifications)

  if (currNotifications.canLoadMore) {
    yield put(updateNotifications({ loading: true }))

    let queryParams = ''
    if (currNotifications.ids.length > 0) {
      queryParams = `?lastLoadedID=${encodeURIComponent(
        currNotifications.ids[currNotifications.ids.length - 1]
      )}`
    }

    const { notifications, canLoadMore } = yield get(
      `${GET_NOTIFICATIONS_URL}${queryParams}`
    )
    const { normalized, ids } = normalizeObjectArray(notifications)

    yield put(
      updateNotifications({
        details: normalized,
        ids,
        canLoadMore,
        loading: false,
      })
    )
  }
}

function* watchLoadNotifications() {
  yield takeLatest(`${loadNotifications}`, startLoadNotifications)
}

export default function* notificationsSaga() {
  yield all([fork(watchLoadNotifications)])
}
