/**
 * @file Reducers for all user related updates (except user object)
 * @author Alwyn Tan
 */

// NOTE: USER OBJECT RESIDES IN AUTH

import { handleActions } from 'redux-actions'
import {
  updateUserDetails,
  setLoadingUser,
  updateOnboardingUser,
  updateOnboardingPage,
  resetOnboardingState,
  updateAvailableUsernames,
  setUpdateUsernameSuccess,
  setUpdateUsernameFailure,
  updateSuperpodUsers,
} from '../actions/user'

const defaultState = {
  loading: false,
  details: {},
  onboardingState: {
    page: 0,
    user: {},
  },
  availableUsernames: {}, // map of usernames -> taken (true/false)
  updateUsernameSuccess: false,
  updateUsernameFailure: '',
  superpods: {
    superpodName: {
      ids: [],
      loading: false,
      canLoadMore: true,
    },
  },
}

export default handleActions(
  {
    [`${updateUserDetails}`]: (state, action) => ({
      ...state,
      details: { ...state.details, ...action.payload },
    }),
    [`${setLoadingUser}`]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [`${resetOnboardingState}`]: state => ({
      ...state,
      onboardingState: { ...defaultState.onboardingState },
    }),
    [`${updateOnboardingUser}`]: (state, action) => ({
      ...state,
      onboardingState: {
        ...state.onboardingState,
        user: { ...state.onboardingState.user, ...action.payload },
      },
    }),
    [`${updateOnboardingPage}`]: (state, action) => ({
      ...state,
      onboardingState: {
        ...state.onboardingState,
        page: state.onboardingState.page + action.payload,
      },
    }),
    [`${updateAvailableUsernames}`]: (state, action) => ({
      ...state,
      availableUsernames: {
        ...state.availableUsernames,
        ...action.payload,
      },
    }),
    [`${setUpdateUsernameSuccess}`]: (state, action) => ({
      ...state,
      updateUsernameSuccess: action.payload,
    }),
    [`${setUpdateUsernameFailure}`]: (state, action) => ({
      ...state,
      updateUsernameFailure: action.payload,
    }),
    [updateSuperpodUsers]: (
      state,
      { payload: { superpodName, ids, ...rest } }
    ) => ({
      ...state,
      superpods: {
        [superpodName]: {
          ...(state.superpods[superpodName] ||
            defaultState.superpods.superpodName),
          ids: [...(state.superpods[superpodName]?.ids || []), ...(ids || [])],
          ...rest,
        },
      },
    }),
  },
  defaultState
)
