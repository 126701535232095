/* eslint-disable import/prefer-default-export */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// attach redux to browser
import rootWrapper from './rootWrapper'

export const registerServiceWorker = () => true
export const wrapRootElement = rootWrapper
