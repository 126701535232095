/**
 * @file CASL Permissions
 * @author Alwyn Tan
 */

import { AbilityBuilder, defineAbility } from '@casl/ability'
import { ROLES } from '../constants'

// The default ability
const ability = defineAbility(can => {
  can('read', 'Event')
  can('read', 'EventDetails')
})

export const updateAbility = (roles = []) => {
  const { can, rules } = new AbilityBuilder()

  for (const role of roles) {
    switch (role) {
      case ROLES.ADMIN:
        can('manage', 'all')
        break
      case ROLES.HOST:
        can('create', 'Event')
        break
      default:
        break
    }
  }

  ability.update(rules)
}

export default ability
