/**
 * @file Reducers for notifications
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import { updateNotifications } from '../actions/notifications'

const defaultState = {
  details: {},
  ids: [],
  loading: false,
  canLoadMore: true,
}

export default handleActions(
  {
    [`${updateNotifications}`]: (
      state,
      { payload: { details, ids, ...rest } }
    ) => ({
      ...state,
      details: { ...state.details, ...details },
      ids: [...state.ids, ...(ids || [])],
      ...rest,
    }),
  },
  defaultState
)
