/**
 * @file Action creators for events
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const exploreEvents = createAction('EXPLORE_EVENTS')
export const updateExploreEvents = createAction('UPDATE_EXPLORE_EVENTS')
export const resetExploreEvents = createAction('RESET_EXPLORE_EVENTS')
export const getEventDetails = createAction('GET_EVENT_DETAILS')
export const updateEventDetails = createAction('UPDATE_EVENT_DETAILS')
export const loadPastEvents = createAction('LOAD_PAST_EVENTS')
export const loadUpcomingEvents = createAction('LOAD_UPCOMING_EVENTS')
export const updatePastEvents = createAction('UPDATE_PAST_EVENTS')
export const updateUpcomingEvents = createAction('UPDATE_UPCOMING_EVENTS')
export const registerEvent = createAction('REGISTER_EVENT')
export const unregisterEvent = createAction('UNREGISTER_EVENT')
export const updateEventAdditionalInfo = createAction(
  'UPDATE_EVENT_ADDITIONAL_INFO'
)
export const updateCreateEventStore = createAction('UPDATE_CREATE_EVENT_STORE')
export const setCreateEventStoreLoading = createAction(
  'SET_CREATE_EVENT_STORE_LOADING'
)
export const resetCreateEventStore = createAction('RESET_CREATE_EVENT_STORE')
export const createNewEvent = createAction('CREATE_NEW_EVENT')
export const updateEvent = createAction('UPDATE_EVENT')
export const cancelEvent = createAction('CANCEL_EVENT')
export const registerEventWaitlist = createAction('REGISTER_EVENT_WAITLIST')
export const unregisterEventWaitlist = createAction('UNREGISTER_EVENT_WAITLIST')

// matches
export const getEventMatches = createAction('GET_EVENT_MATCHES')
export const setEventMatches = createAction('SET_EVENT_MATCHES')
export const confirmEventMatches = createAction('CONFIRM_EVENT_MATCHES')
