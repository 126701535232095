/**
 * @file Authentication related sagas
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { serialize } from 'object-to-formdata'
import { all, fork, put, takeLatest, select, call } from 'redux-saga/effects'
import { navigate } from 'gatsby'
import {
  USER_UPDATE_URL,
  USER_INFO_URL,
  VALIDATE_USERNAME_URL,
  UPDATE_USERNAME_URL,
  LOAD_SUPERPOD_USERS_URL,
} from '../constants'
import {
  getUserDetails,
  updateUserDetails,
  setLoadingUser,
  updateUser,
  onboardUser,
  resetOnboardingState,
  validateUsername,
  updateAvailableUsernames,
  updateUsername,
  setUpdateUsernameSuccess,
  setUpdateUsernameFailure,
  loadSuperpodUsers,
  updateSuperpodUsers,
} from '../actions/user'
import { setCurrentUser } from '../actions/auth'
import { get, post } from '../utils/saga-fetch'
import { makeURLWithQueryParams } from '../utils/url'
import { normalizeObjectArray } from '../utils'

const loadingWrapper = fn =>
  function* wrapper(action) {
    yield put(setLoadingUser(true))
    yield call(fn, action)
    yield put(setLoadingUser(false))
  }

function* startUpdateUser(action) {
  const userData = action.payload
  const accessToken = yield select(state => state.auth.accessToken)

  const toastID = toast.loading('Updating your profile')

  try {
    const { user } = yield fetch(USER_UPDATE_URL, {
      method: 'POST',
      body: serialize(userData),
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(response => response.json())
    yield put(setCurrentUser(user))
    yield put(resetOnboardingState())
    toast.success('Profile Updated', { id: toastID })
    if (window.location.pathname === '/app/profile/edit')
      navigate('/app/profile')
  } catch (err) {
    console.error(err)
    toast.error('Error updating profile', { id: toastID })
  }
}

function* startUpdateUsername(action) {
  const username = action.payload

  const { success, error } = yield post(UPDATE_USERNAME_URL, {
    username,
  })

  if (success) {
    const user = yield select(state => state.auth.user)

    yield put(setUpdateUsernameSuccess(true))
    yield put(setCurrentUser({ ...user, username }))
  } else {
    yield put(setUpdateUsernameFailure(error))
  }
}

function* startGetUserDetails(action) {
  const { id } = action.payload

  const { user } = yield get(`${USER_INFO_URL}?id=${encodeURIComponent(id)}`)

  if (user) yield put(updateUserDetails({ [id]: user }))
  else yield put(updateUserDetails({ [id]: { notExist: true } }))
}

function* startValidateUsername(action) {
  const username = action.payload
  const { success } = yield get(`${VALIDATE_USERNAME_URL}/${username}`)

  if (success) {
    yield put(updateAvailableUsernames({ [username]: true }))
  } else {
    yield put(updateAvailableUsernames({ [username]: false }))
  }
}

function* startLoadSuperpodUsers(action) {
  const { superpodName } = action.payload
  const loadedUsers = (yield select(
    state => state.user.superpods[superpodName]
  )) || {
    ids: [],
    loading: false,
    canLoadMore: true,
  }

  if (loadedUsers.canLoadMore) {
    yield put(updateSuperpodUsers({ superpodName, loading: true }))

    const queryParams = {}
    if (loadedUsers.ids.length > 0)
      queryParams.lastLoadedID = loadedUsers.ids[loadedUsers.ids.length - 1]

    const URL = makeURLWithQueryParams(
      `${LOAD_SUPERPOD_USERS_URL}/${superpodName}`,
      queryParams
    )
    const { users, canLoadMore } = yield get(URL)
    const { normalized, ids } = normalizeObjectArray(users)
    yield put(updateUserDetails(normalized))
    yield put(
      updateSuperpodUsers({ superpodName, ids, canLoadMore, loading: false })
    )
  }
}

function* startOnboardUser() {
  const user = { ...(yield select(state => state.user.onboardingState.user)) }
  if (user.birthDate) user.birthDate = dayjs(user.birthDate).toISOString()

  yield call(startUpdateUser, { payload: user })
}

function* watchUpdateUser() {
  yield takeLatest(`${updateUser}`, loadingWrapper(startUpdateUser))
}

function* watchUpdateUsername() {
  yield takeLatest(`${updateUsername}`, loadingWrapper(startUpdateUsername))
}

function* watchGetUserDetails() {
  yield takeLatest(`${getUserDetails}`, loadingWrapper(startGetUserDetails))
}

function* watchValidateUsername() {
  yield takeLatest(`${validateUsername}`, loadingWrapper(startValidateUsername))
}

function* watchLoadSuperpodUsers() {
  yield takeLatest(`${loadSuperpodUsers}`, startLoadSuperpodUsers)
}

function* watchOnboardUser() {
  yield takeLatest(`${onboardUser}`, loadingWrapper(startOnboardUser))
}

export default function* userSaga() {
  yield all([
    fork(watchUpdateUser),
    fork(watchUpdateUsername),
    fork(watchGetUserDetails),
    fork(watchValidateUsername),
    fork(watchOnboardUser),
    fork(watchLoadSuperpodUsers),
  ])
}
