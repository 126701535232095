/**
 * @file Reducers for all user verification requests, permissions, etc (excludes auth verification for now)
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'
import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  requestSuperpodVerification,
  requestEmailVerification,
  verifyEmail,
  setEmailVerificationSuccess,
  updateVerificationDetails,
  findVerification,
} from '../actions/verification'
import {
  FIND_VERIFICATIONS_URL,
  REQUEST_EMAIL_VERIFICATION_URL,
  REQUEST_SUPERPOD_VERIFICATION_URL,
  VERIFY_EMAIL_URL,
} from '../constants'
import { normalizeObjectArray } from '../utils'
import { get, post } from '../utils/saga-fetch'
import { makeURLWithQueryParams } from '../utils/url'

function* startFindVerification(action) {
  const { type, value } = action.payload

  const url = makeURLWithQueryParams(FIND_VERIFICATIONS_URL, { type, value })
  const { verifications } = yield get(url)

  if (verifications)
    yield put(
      updateVerificationDetails(normalizeObjectArray(verifications).normalized)
    )
}

function* startRequestSuperpodVerification(action) {
  const { superpodName } = action.payload

  const toastID = toast.loading('Requesting Verification')
  const {
    verification,
    success,
  } = yield post(REQUEST_SUPERPOD_VERIFICATION_URL, { superpodName })

  if (success) {
    toast.success('Verification Requested', { id: toastID })
    yield put(
      updateVerificationDetails(normalizeObjectArray([verification]).normalized)
    )
  } else {
    toast.error('Error Requesting Verification', { id: toastID })
  }
}

// adds the user's email, then requests verification
function* startRequestEmailVerification(action) {
  const email = action.payload

  const toastID = toast.loading('Verifying your email')
  const { success } = yield post(REQUEST_EMAIL_VERIFICATION_URL, {
    email,
  })

  if (success) {
    toast.success('Email verification email sent', { id: toastID })
  } else {
    toast.error('Error sending email verification', { id: toastID })
  }
}

function* startVerifyEmail(action) {
  const { v, t } = action.payload

  const { success } = yield get(
    `${VERIFY_EMAIL_URL}?v=${encodeURIComponent(v)}&t=${encodeURIComponent(t)}`
  )

  yield put(setEmailVerificationSuccess(!!success))
}

function* watchFindVerification() {
  yield takeLatest(`${findVerification}`, startFindVerification)
}

function* watchRequestSuperpodVerification() {
  yield takeLatest(
    `${requestSuperpodVerification}`,
    startRequestSuperpodVerification
  )
}

function* watchRequestEmailVerification() {
  yield takeLatest(`${requestEmailVerification}`, startRequestEmailVerification)
}

function* watchStartVerifyEmail() {
  yield takeLatest(`${verifyEmail}`, startVerifyEmail)
}

// todo: find if there is a founder verification exisintg

export default function* verificationSaga() {
  yield all([
    fork(watchFindVerification),
    fork(watchRequestSuperpodVerification),
    fork(watchRequestEmailVerification),
    fork(watchStartVerifyEmail),
  ])
}
