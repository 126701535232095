/**
 * @file Action creators for chat
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const getChatRoom = createAction('GET_CHAT_ROOM')
export const loadChatRooms = createAction('LOAD_CHAT_ROOMS')
export const loadChatMessages = createAction('LOAD_CHAT_MESSAGES')
export const sendChatMessage = createAction('SEND_CHAT_MESSAGE')
export const seenChatMessage = createAction('SEEN_CHAT_MESSAGE')
export const updateChatRooms = createAction('UPDATE_CHAT_ROOMS')
export const updateChatMessages = createAction('UPDATE_CHAT_MESSAGES')
export const updateChatRoomDetails = createAction('UPDATE_CHAT_ROOM_DETAILS')
export const updateChatMessageDetails = createAction(
  'UPDATE_CHAT_MESSAGE_DETAILS'
)
export const moveChatMessageToFront = createAction('MOVE_CHAT_MESSAGE_TO_FRONT')
export const moveChatRoomToFront = createAction('MOVE_CHAT_ROOM_TO_FRONT')
export const createChatRoom = createAction('CREATE_CHAT_ROOM')
