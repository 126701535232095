/**
 * @file Socket utility functions
 * @author Alwyn Tan
 */

import { put, take } from 'redux-saga/effects'
import io from 'socket.io-client'
import { BASE_SOCKET_URL } from '../constants'

export const connectSocket = accessToken =>
  io(BASE_SOCKET_URL, {
    auth: { token: accessToken },
    query: { token: accessToken },
  })

/**
 * Waits and takes the emitted action from the redux saga channel and closes it
 * @param {Object} channel Redux Saga Event Channel instance
 */
export function* takeAndCloseChannel(channel) {
  const channelActions = yield take(channel)
  // eslint-disable-next-line no-restricted-syntax
  for (const channelAction of channelActions) yield put(channelAction)
  channel.close()
}
