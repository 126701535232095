/**
 * @file Authentication related sagas
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import {
  setCurrentUser,
  setAccessToken,
  silentLogin,
  setAuthLoading,
  logout,
  setAuthPhoneNumber,
  setAuthSuccessCallback,
  requestLogin,
  login,
} from '../actions/auth'
import {
  REFRESH_TOKEN_URL,
  LOGOUT_URL,
  REQUEST_LOGIN_URL,
  LOGIN_URL,
} from '../constants'
import { get, post } from '../utils/saga-fetch'
import { updateAbility } from '../casl/ability'

const subscribeToPush = async ({ accessToken, requestPermission }) => {
  // asks for notif permissions
  if (accessToken && 'Notification' in window) {
    // skip if current user already denied it
    if (Notification.permission === 'denied' && !requestPermission) return

    // asks for perm if needed
    if (Notification.permission !== 'granted') {
      const perm = await Notification.requestPermission()
      if (perm !== 'granted') return
    }

    if (navigator?.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'SUBSCRIBE_PUSH_NOTIFICATION',
        accessToken,
      })
    }
  }
}

function* startJWTExpiryTimeout(expiry) {
  if (expiry) {
    const channel = eventChannel(emit => {
      setTimeout(() => {
        emit(silentLogin())
      }, expiry)

      return () => {}
    })

    const channelAction = yield take(channel)
    yield put(channelAction)
    channel.close()
  }
}

function* postAuth({ payload, user }) {
  updateAbility(payload.roles)
  yield put(setCurrentUser(user))
  yield put(setAccessToken(payload.accessToken))
  yield fork(startJWTExpiryTimeout, payload.expiry)

  if (payload.accessToken) {
    const authSuccessCallback = yield select(
      state => state.auth.authSuccessCallback
    )
    if (authSuccessCallback) authSuccessCallback()
    yield setAuthSuccessCallback(null)
  }
}

function* startSilentLogin() {
  yield put(setAuthLoading(true))
  const { payload, user } = yield get(REFRESH_TOKEN_URL, {
    includeCredentials: true,
  })
  if (!payload?.accessToken) {
    yield put(setAccessToken(null))
  } else {
    yield fork(postAuth, { payload, user })
    yield call(subscribeToPush, { accessToken: payload.accessToken })
  }
  yield put(setAuthLoading(false))
}

function* startLogout() {
  const accessToken = yield select(state => state.auth.accessToken)
  try {
    if (navigator?.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'UNSUBSCRIBE_PUSH_NOTIFICATION',
        accessToken,
      })
    }

    yield fetch(LOGOUT_URL, { credentials: 'same-origin' })
    window.location.reload()
  } catch (err) {
    console.error(err)
  }
}

function* startRequestLogin(action) {
  yield put(setAuthLoading(true))
  const phoneNumber = action.payload

  const { success, error } = yield post(REQUEST_LOGIN_URL, { phoneNumber })

  if (success) {
    yield put(setAuthPhoneNumber(phoneNumber))
  } else {
    const defaultErrorMessage =
      'Sorry, there appears to be a problem. Please try again later.'

    toast.error(error || defaultErrorMessage)
  }
  yield put(setAuthLoading(false))
}

function* startLogin(action) {
  yield put(setAuthLoading(true))
  const code = action.payload
  const phoneNumber = yield select(state => state.auth.authPhoneNumber)

  const { payload, user, error } = yield post(
    LOGIN_URL,
    { phoneNumber, code },
    { includeCredentials: true }
  )

  if (error) {
    const defaultErrorMessage =
      'Sorry, there appears to be a problem logging you in. Please try again later.'

    toast.error(error || defaultErrorMessage)
  } else {
    yield fork(postAuth, { payload, user })
    yield call(subscribeToPush, {
      accessToken: payload.accessToken,
      requestPermission: true,
    })
  }
  yield put(setAuthLoading(false))
}

function* watchLogout() {
  yield takeLatest(`${logout}`, startLogout)
}

function* watchSilentLogin() {
  yield takeLatest(`${silentLogin}`, startSilentLogin)
}

function* watchRequestLogin() {
  yield takeLatest(`${requestLogin}`, startRequestLogin)
}

function* watchLogin() {
  yield takeLatest(`${login}`, startLogin)
}

export default function* authSaga() {
  yield all([
    fork(watchSilentLogin),

    // back to normal
    fork(watchRequestLogin),
    fork(watchLogin),
    fork(watchLogout),
  ])
}
