/**
 * @file Action creators for authentication
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const setCurrentUser = createAction('SET_CURRENT_USER')
export const setAccessToken = createAction('SET_ACCESS_TOKEN')
export const setAuthLoading = createAction('SET_AUTH_LOADING')
export const silentLogin = createAction('SILENT_LOGIN')
export const registerAccount = createAction('REGISTER_ACCOUNT')
export const setAuthPhoneNumber = createAction('SET_AUTH_PHONE_NUMBER')
export const setAuthSuccessCallback = createAction('SET_AUTH_SUCCESS_CALLBACK')
export const logout = createAction('LOGOUT')
export const requestLogin = createAction('REQUEST_LOGIN')
export const login = createAction('LOGIN')
