/**
 * @file fetch helpers
 * @author Alwyn Tan
 */

import { select } from 'redux-saga/effects'

/**
 * Makes a post request to the specified url
 * @param {String} url url to post to
 * @param {Object} body body in json format
 * @param {Object} options
 * @param {Boolean} options.includeCredentials include credentials on request
 */
export function* post(url, body, options = {}) {
  const headers = { 'Content-Type': 'application/json' }

  const accessToken = yield select(state => state.auth.accessToken)

  const request = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  }

  if (options.includeCredentials) request.credentials = 'include'
  else if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  try {
    return yield fetch(url, request).then(response => response.json())
  } catch (err) {
    console.error(err)
    return null
  }
}

/**
 * Makes a get request to the specified url
 * @param {String} url url to get
 * @param {Object} options
 * @param {Boolean} options.includeCredentials include credentials on request
 */
export function* get(url, options = {}) {
  const headers = {}

  const accessToken = yield select(state => state.auth.accessToken)

  const request = { headers }
  if (options.includeCredentials) request.credentials = 'include'
  else if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  try {
    return yield fetch(url, request).then(response => response.json())
  } catch (err) {
    console.error(err)
    return null
  }
}
