/* eslint-disable import/prefer-default-export */

/**
 * @file Action creators for stripe related logic
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const checkoutEvent = createAction('CHECKOUT_EVENT')
