/**
 * @file Reducers for chat
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  moveChatMessageToFront,
  moveChatRoomToFront,
  updateChatMessageDetails,
  updateChatMessages,
  updateChatRoomDetails,
  updateChatRooms,
} from '../actions/chat'

const defaultState = {
  messageDetails: {},
  roomDetails: {},
  rooms: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },
  messages: {
    roomID: {
      ids: [],
      loading: false,
      canLoadMore: true,
    },
  },
}

export default handleActions(
  {
    [updateChatRoomDetails]: (state, action) => ({
      ...state,
      roomDetails: { ...state.roomDetails, ...action.payload },
    }),
    [updateChatMessageDetails]: (state, action) => ({
      ...state,
      messageDetails: { ...state.messageDetails, ...action.payload },
    }),
    [moveChatRoomToFront]: (state, action) => {
      const roomID = action.payload.id
      const ids = state.rooms.ids.filter(id => id !== roomID)
      ids.unshift(roomID)
      return { ...state, rooms: { ...state.rooms, ids } }
    },
    [moveChatMessageToFront]: (state, action) => {
      const roomID = action.payload.room
      const messageID = action.payload.id

      const curr = state.messages[roomID] || defaultState.messages.roomID
      const ids = curr.ids.filter(id => id !== messageID)
      ids.unshift(messageID)
      return {
        ...state,
        messages: { ...state.messages, [roomID]: { ...curr, ids } },
      }
    },
    [updateChatRooms]: (state, { payload: { ids, ...rest } }) => ({
      ...state,
      rooms: {
        ...state.rooms,
        ids: [...state.rooms.ids, ...(ids || [])],
        ...rest,
      },
    }),
    [updateChatMessages]: (state, { payload: { roomID, ids, ...rest } }) => ({
      ...state,
      messages: {
        [roomID]: {
          ...(state.messages[roomID] || defaultState.messages.roomID),
          ids: [...(state.messages[roomID]?.ids || []), ...(ids || [])],
          ...rest,
        },
      },
    }),
  },
  defaultState
)
