// v2 version of events. Migrate event to events when complete

import { all, fork, select, put, takeLatest, call } from 'redux-saga/effects'
import { serialize } from 'object-to-formdata'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import {
  GET_EVENT_URL,
  REGISTER_EVENT_URL,
  UPDATE_EVENT_ADDITIONAL_INFO,
  GET_EVENT_MATCHES_URL,
  CONFIRM_EVENT_MATCHES_URL,
  CREATE_EVENT_URL,
  UPDATE_EVENT_URL,
  CANCEL_EVENT_URL,
  UNREGISTER_EVENT_URL,
  REGISTER_EVENT_WAITLIST_URL,
  UNREGISTER_EVENT_WAITLIST_URL,
  GET_REGISTERED_EVENTS_URL,
  EXPLORE_EVENTS_URL,
} from '../constants'
import { post, get } from '../utils/saga-fetch'
import {
  getEventDetails,
  updateEventDetails,
  registerEvent,
  updateEvent,
  unregisterEvent,
  loadUpcomingEvents,
  loadPastEvents,
  updateUpcomingEvents,
  updatePastEvents,
  updateEventAdditionalInfo,
  getEventMatches,
  setEventMatches,
  confirmEventMatches,
  createNewEvent,
  cancelEvent,
  registerEventWaitlist,
  unregisterEventWaitlist,
  exploreEvents,
  updateExploreEvents,
  setCreateEventStoreLoading,
} from '../actions/events'
import { normalizeObjectArray } from '../utils'
import { makeURLWithQueryParams } from '../utils/url'

function* startExploreEvents(action) {
  const { city } = action.payload

  const exploredEvents = yield select(state => state.events.explore)

  if (exploredEvents.canLoadMore) {
    yield put(updateExploreEvents({ loading: true }))

    const queryParams = {}
    if (exploredEvents.ids.length > 0)
      queryParams.lastLoadedID =
        exploredEvents.ids[exploredEvents.ids.length - 1]
    if (city && city !== 'Anywhere') queryParams.city = city

    const URL = makeURLWithQueryParams(EXPLORE_EVENTS_URL, queryParams)
    const { events, canLoadMore } = yield get(URL)
    const { normalized, ids } = normalizeObjectArray(events)

    yield put(updateEventDetails(normalized))
    yield put(updateExploreEvents({ ids, canLoadMore, loading: false }))
  }
}

function* startLoadPastEvents() {
  const pastEvents = yield select(state => state.events.past)

  if (pastEvents.canLoadMore) {
    yield put(updatePastEvents({ loading: true }))

    const queryParams = { isPast: true }
    if (pastEvents.ids.length > 0)
      queryParams.lastLoadedID = pastEvents.ids[pastEvents.ids.length - 1]

    const URL = makeURLWithQueryParams(GET_REGISTERED_EVENTS_URL, queryParams)
    const { events, canLoadMore } = yield get(URL)
    const { normalized, ids } = normalizeObjectArray(events)

    yield put(updateEventDetails(normalized))
    yield put(updatePastEvents({ ids, canLoadMore, loading: false }))
  }
}

function* startLoadUpcomingEvents(action) {
  const reset = action?.payload?.reset
  const upcomingEvents = yield select(state => state.events.upcoming)

  if (upcomingEvents.canLoadMore || reset) {
    yield put(updateUpcomingEvents({ loading: true }))

    const queryParams = { isPast: false }
    if (upcomingEvents.ids.length > 0 && !reset)
      queryParams.lastLoadedID =
        upcomingEvents.ids[upcomingEvents.ids.length - 1]

    const URL = makeURLWithQueryParams(GET_REGISTERED_EVENTS_URL, queryParams)
    const { events, canLoadMore } = yield get(URL)
    const { normalized, ids } = normalizeObjectArray(events)

    yield put(updateEventDetails(normalized))
    yield put(updateUpcomingEvents({ ids, reset, canLoadMore, loading: false }))
  }
}

function* setLoadedEvent(eventDetails, id) {
  if (eventDetails) {
    const { normalized } = normalizeObjectArray([eventDetails])
    yield put(updateEventDetails(normalized))
  } else {
    yield put(updateEventDetails({ [id]: { notFound: true } }))
  }
}

// sets loading to a specific event
function* setLoadingEvent(id) {
  const eventDetails = yield select(state => state.events.details[id] || {})
  yield put(
    updateEventDetails({ [id]: { ...eventDetails, loading: true, id } })
  )
}

function* startGetEventDetails(action) {
  const { id, inv } = action.payload
  yield call(setLoadingEvent, id)
  const url = makeURLWithQueryParams(`${GET_EVENT_URL}/${id}`, { inv })
  const { event } = yield get(url)
  yield call(setLoadedEvent, event, id)
}

// TODO: handle errors
function* startRegisterEvent(action) {
  const { eventID, code } = action.payload
  yield call(setLoadingEvent, eventID)
  const toastID = toast.loading('Registering')

  const { event } = yield post(REGISTER_EVENT_URL, {
    eventID,
    code,
  })

  toast.success('Registered', { id: toastID })
  yield call(setLoadedEvent, event, eventID)
  yield put(loadUpcomingEvents({ reset: true }))
  navigate(`/app/events/${eventID}`, { replace: true })
}

function* startUnregisterEvent(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event, refundRequired } = yield post(UNREGISTER_EVENT_URL, {
    eventID,
  })

  if (refundRequired) {
    // todo later or maybe put some placeholder here to return
  } else {
    yield call(setLoadedEvent, event, eventID)
  }
}

function* startUpdateEventAdditionalInfo(action) {
  const { additionalInfo, eventID } = action.payload

  const { event } = yield post(UPDATE_EVENT_ADDITIONAL_INFO, {
    additionalInfo,
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* startGetEventMatches(action) {
  const id = action.payload

  // loading
  let existingMatches = yield select(state => state.events.matches)
  yield put(setEventMatches({ ...existingMatches, [id]: { loading: true } }))

  // received
  const { matches, isFixed } = yield get(`${GET_EVENT_MATCHES_URL}/${id}`)
  existingMatches = yield select(state => state.events.matches)
  yield put(
    setEventMatches({ ...existingMatches, [id]: { data: matches, isFixed } })
  )
}

function* startConfirmEventMatches(action) {
  const { matches, eventID } = action.payload

  // loading
  let existingMatches = yield select(state => state.events.matches)
  yield put(
    setEventMatches({
      ...existingMatches,
      [eventID]: { ...existingMatches[eventID], loading: true },
    })
  )

  const { success } = yield post(`${CONFIRM_EVENT_MATCHES_URL}`, {
    matches,
    eventID,
  })

  if (success) {
    existingMatches = yield select(state => state.events.matches)
    yield put(
      setEventMatches({
        ...existingMatches,
        [eventID]: {
          ...existingMatches[eventID],
          loading: false,
          isFixed: true,
        },
      })
    )
  }

  // todo: add error handling
}

function* startCreateNewEvent(action) {
  const id = action.payload
  const formData = yield select(state => state.events.createEventStore.formData)
  const accessToken = yield select(state => state.auth.accessToken)
  const userID = yield select(state => state.auth.user?.id)
  yield put(setCreateEventStoreLoading(true))

  const dataToSubmit = serialize(formData)
  dataToSubmit.append('host', userID)

  if (id) dataToSubmit.append('id', id)
  const ENDPOINT_URL = id ? UPDATE_EVENT_URL : CREATE_EVENT_URL

  try {
    const { eventID } = yield fetch(ENDPOINT_URL, {
      method: 'POST',
      body: dataToSubmit,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(response => response.json())

    navigate(`/app/events/${eventID}`)
  } catch (err) {
    console.error(err)
  }

  yield put(setCreateEventStoreLoading(false))
}

function* startCancelEvent(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event } = yield post(CANCEL_EVENT_URL, {
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* startRegisterEventWaitlist(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)
  const { event } = yield post(REGISTER_EVENT_WAITLIST_URL, {
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* startUnregisterEventWaitlist(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event } = yield post(UNREGISTER_EVENT_WAITLIST_URL, { eventID })

  yield call(setLoadedEvent, event, eventID)
}

function* watchExploreEvents() {
  yield takeLatest(`${exploreEvents}`, startExploreEvents)
}

function* watchRegisterEvent() {
  yield takeLatest(`${registerEvent}`, startRegisterEvent)
}

function* watchUnregisterEvent() {
  yield takeLatest(`${unregisterEvent}`, startUnregisterEvent)
}

function* watchRegisterEventWaitlist() {
  yield takeLatest(`${registerEventWaitlist}`, startRegisterEventWaitlist)
}

function* watchUnregisterEventWaitlist() {
  yield takeLatest(`${unregisterEventWaitlist}`, startUnregisterEventWaitlist)
}

function* watchGetEventDetails() {
  yield takeLatest(`${getEventDetails}`, startGetEventDetails)
}

function* watchLoadUpcomingEvents() {
  yield takeLatest(`${loadUpcomingEvents}`, startLoadUpcomingEvents)
}

function* watchLoadPastEvents() {
  yield takeLatest(`${loadPastEvents}`, startLoadPastEvents)
}

function* watchConfirmEventMatches() {
  yield takeLatest(`${confirmEventMatches}`, startConfirmEventMatches)
}

function* watchUpdateEventAdditionalInfo() {
  yield takeLatest(
    `${updateEventAdditionalInfo}`,
    startUpdateEventAdditionalInfo
  )
}

function* watchGetEventMatches() {
  yield takeLatest(`${getEventMatches}`, startGetEventMatches)
}

function* watchCreateNewEvent() {
  yield takeLatest(`${createNewEvent}`, startCreateNewEvent)
}

function* watchUpdateEvent() {
  yield takeLatest(`${updateEvent}`, startCreateNewEvent)
}

function* watchCancelEvent() {
  yield takeLatest(`${cancelEvent}`, startCancelEvent)
}

export default function* eventsSaga() {
  yield all([
    fork(watchExploreEvents),
    fork(watchGetEventDetails),
    fork(watchGetEventMatches),
    fork(watchLoadUpcomingEvents),
    fork(watchLoadPastEvents),
    fork(watchConfirmEventMatches),
    fork(watchRegisterEvent),
    fork(watchUnregisterEvent),
    fork(watchUpdateEventAdditionalInfo),
    fork(watchCreateNewEvent),
    fork(watchUpdateEvent),
    fork(watchCancelEvent),
    fork(watchRegisterEventWaitlist),
    fork(watchUnregisterEventWaitlist),
  ])
}
