// v2 version of events. Migrate event to events when complete

import { all, fork, takeLatest } from 'redux-saga/effects'
import { checkoutEvent } from '../actions/stripe'
import { CHECKOUT_EVENT_SESSION_URL } from '../constants'
import StripeService from '../services/stripe'
import { post } from '../utils/saga-fetch'

function* startCheckoutEvent(action) {
  const { eventID, extraSeats } = action.payload
  const { sessionID } = yield post(CHECKOUT_EVENT_SESSION_URL, {
    eventID,
    extraSeats,
  })
  yield StripeService.showCheckout(sessionID)
}

function* watchCheckoutEvent() {
  yield takeLatest(`${checkoutEvent}`, startCheckoutEvent)
}

export default function* stripeSaga() {
  yield all([fork(watchCheckoutEvent)])
}
