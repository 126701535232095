/**
 * @file Pod sagas
 * @author Alwyn Tan
 */

import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { getSuperpod, updateSuperpodDetails } from '../actions/pod'
import { GET_SUPERPOD_URL } from '../constants'
import { normalizeObjectArray } from '../utils'
import { get } from '../utils/saga-fetch'

function* startGetSuperpod(action) {
  const name = action.payload

  const { superpod } = yield get(
    `${GET_SUPERPOD_URL}?name=${encodeURIComponent(name)}`
  )

  if (!superpod) {
    yield put(updateSuperpodDetails({ undefined: { name, notFound: true } }))
  } else {
    const { normalized } = normalizeObjectArray([superpod])
    yield put(updateSuperpodDetails(normalized))
  }
}

function* watchGetSuperpod() {
  yield takeLatest(`${getSuperpod}`, startGetSuperpod)
}

export default function* podSaga() {
  yield all([fork(watchGetSuperpod)])
}
