/**
 * @file Stripe service to help handle stripe setup and checkouts
 * @author Alwyn Tan
 */

import { loadStripe } from '@stripe/stripe-js'

const StripeService = (() => ({
  showCheckout: async sessionID => {
    const stripe = await loadStripe(process.env.GATSBY_STRIPE_API_KEY)
    const result = await stripe.redirectToCheckout({ sessionId: sessionID })

    if (result.error) {
      console.error(result.error)
    }
  },
}))()

export default StripeService
