// v2 version of event. This version supports loading more events

/**
 * @file Reducers for events
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import clamp from 'lodash/clamp'
import {
  setEventMatches,
  updateEventDetails,
  updateCreateEventStore,
  setCreateEventStoreLoading,
  resetCreateEventStore,
  updatePastEvents,
  updateUpcomingEvents,
  updateExploreEvents,
  resetExploreEvents,
} from '../actions/events'

const CREATE_EVENT_SEGMENTS = 5

const defaultState = {
  details: {},
  past: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },
  upcoming: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },
  explore: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },
  matches: {},
  createEventStore: {
    loading: false,
    progress: 0,
    segments: CREATE_EVENT_SEGMENTS,
    formData: {
      settings: {
        showGuestList: true,
        showFunFacts: true,
        showProfessions: true,
        showQuestion: true,
      },
    },
  }, // the store for event creation
  // cancelledEvents?
}

export default handleActions(
  {
    [updateExploreEvents]: (state, { payload: { ids, ...rest } }) => ({
      ...state,
      explore: {
        ...state.explore,
        ids: [...state.explore.ids, ...(ids || [])],
        ...rest,
      },
    }),
    [resetExploreEvents]: state => ({
      ...state,
      explore: {
        ids: [],
        loading: false,
        canLoadMore: true,
      },
    }),
    [updateEventDetails]: (state, action) => ({
      ...state,
      details: { ...state.details, ...action.payload },
    }),
    [updatePastEvents]: (state, { payload: { ids, ...rest } }) => ({
      ...state,
      past: {
        ...state.past,
        ids: [...state.past.ids, ...(ids || [])],
        ...rest,
      },
    }),
    [updateUpcomingEvents]: (
      state,
      { payload: { ids = [], reset, ...rest } }
    ) => ({
      ...state,
      upcoming: {
        ...state.upcoming,
        ids: reset ? [...ids] : [...state.upcoming.ids, ...ids],
        ...rest,
      },
    }),
    [`${setEventMatches}`]: (state, action) => ({
      ...state,
      matches: action.payload,
    }),
    [`${updateCreateEventStore}`]: (state, action) => ({
      ...state,
      createEventStore: {
        ...state.createEventStore,
        progress: clamp(action.payload.progress, 0, CREATE_EVENT_SEGMENTS - 1),
        formData: {
          ...state.createEventStore.formData,
          ...action.payload.data,
        },
      },
    }),
    [`${setCreateEventStoreLoading}`]: (state, action) => ({
      ...state,
      createEventStore: {
        ...state.createEventStore,
        loading: action.payload,
      },
    }),
    [`${resetCreateEventStore}`]: state => ({
      ...state,
      createEventStore: defaultState.createEventStore,
    }),
  },
  defaultState
)
