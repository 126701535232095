/**
 * @file Reducers for pods
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import { updateSuperpodDetails } from '../actions/pod'

const defaultState = {
  superpodDetails: {},
  nameToSuperpod: {}, // [name] -> superpodID mapper
}

export default handleActions(
  {
    [`${updateSuperpodDetails}`]: (state, action) => ({
      ...state,
      superpodDetails: {
        ...state.superpodDetails,
        ...action.payload,
      },
      nameToSuperpod: {
        ...state.nameToSuperpod,
        ...Object.values(action.payload).reduce(
          (acc, curr) => ({ ...acc, [curr.name]: curr.id }),
          {}
        ),
      },
    }),
  },
  defaultState
)
