/**
 * @file Reducers for verifications
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  setEmailVerificationSuccess,
  updateVerificationDetails,
} from '../actions/verification'

const defaultState = {
  details: {},
  emailVerificationSuccessful: undefined,
}

export default handleActions(
  {
    [`${setEmailVerificationSuccess}`]: (state, action) => ({
      ...state,
      emailVerificationSuccessful: action.payload,
    }),
    [`${updateVerificationDetails}`]: (state, action) => ({
      ...state,
      details: {
        ...state.details,
        ...action.payload,
      },
    }),
  },
  defaultState
)
