/* eslint-disable import/prefer-default-export */

/**
 * @file user verification actions
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const requestSuperpodVerification = createAction(
  'REQUEST_SUPERPOD_VERIFICATION'
)
export const requestEmailVerification = createAction(
  'REQUEST_EMAIL_VERIFICATION'
)
export const verifyEmail = createAction('VERIFY_EMAIL')
export const setEmailVerificationSuccess = createAction(
  'SET_EMAIL_VERIFICATION_SUCCESS'
)
export const updateVerificationDetails = createAction(
  'UPDATE_VERIFICATION_DETAILS'
)
export const findVerification = createAction('FIND_VERIFICATION')
