/**
 * @file Action creators for user related events (update profile, etc)
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const setLoadingUser = createAction('SET_LOADING_USER')
export const updateUser = createAction('UPDATE_USER')
export const getUserDetails = createAction('GET_USER_DETAILS')
export const updateUserDetails = createAction('UPDATE_USER_DETAILS')
export const validateUsername = createAction('VALIDATE_USERNAME')
export const updateAvailableUsernames = createAction(
  'UPDATE_AVAILABLE_USERNAMES'
)
export const updateUsername = createAction('UPDATE_USERNAME')
export const setUpdateUsernameSuccess = createAction(
  'SET_UPDATE_USERNAME_SUCESS'
)
export const setUpdateUsernameFailure = createAction(
  'SET_UPDATE_USERNAME_FAILURE'
)
export const loadSuperpodUsers = createAction('LOAD_SUPERPOD_USERS')
export const updateSuperpodUsers = createAction('UPDATE_SUPERPOD_USERS')

// onboarding states
export const updateOnboardingPage = createAction('UPDATE_ONBOARDING_PAGE')
export const updateOnboardingUser = createAction('UPDATE_ONBOARDING_USER')
export const onboardUser = createAction('ONBOARD_USER')
export const resetOnboardingState = createAction('RESET_ONBOARDING_STATE')
